import React from "react"
import Helmet from 'react-helmet';
import { graphql } from "gatsby"
import Layout from "../components/layout";
import PostImage from "../components/post-image"


export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { site, markdownRemark, allMarkdownRemark } = data // data.markdownRemark holds your post data
  const { siteMetadata } = site
  const { frontmatter, html } = markdownRemark
  const {edges}= allMarkdownRemark

  const pictures = edges
    .filter(edge => edge.node.frontmatter.catagory==="Impressionen" && edge.node.frontmatter.page===frontmatter.title) // You can filter your posts based on some criteria
    .map(edge => <div class="w-full rounded hover:opacity-90"> <PostImage key={Math.random().toString(36).substr(2, 9)} post={edge.node}  /></div> )

  return (
    <>
    <Layout>
      <Helmet>
        <title>{frontmatter.title} | {siteMetadata.title}</title>
        <meta charset="UTF-8" />
        <meta name="description" content={frontmatter.metaDescription} />
        <meta name="author" content="Schatzkammer Lübeck" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
     
 
  <section class="bg-hero-texture">
  <div class="container mx-auto p-4">
    <div class="flex flex-wrap">
      <div class="w-full align-top md:w-5/12 ">
        <div class="relative flex flex-col min-w-0 break-words w-full mb-2 shadow-lg rounded-lg skBrown">
          <img alt="..." src={frontmatter.thumbnail}>
          </img>
          <blockquote class="relative p-8 mb-4">
            <h4 class="text-xl font-bold text-white">
              {frontmatter.title}
            </h4>
            <p class="text-md font-light mt-2 text-white">
              {frontmatter.metaDescription}
            </p>
          </blockquote>
        </div>
      </div>
      <div class="w-full md:w-6/12 ml-auto  items-center">
        <div dangerouslySetInnerHTML={{ __html: html }}/>
      </div>
    </div>
  </div>
</section>

<section class="py-4"> 
      <div class="grid md:grid-cols-2 grid-cols-1 mx-auto gap-2 m-8">  
      {pictures}
      </div>
</section>     
     
     </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        thumbnail
        metaDescription
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            thumbnail
            catagory
            page
            metaDescription
          }
        }
      }
    }
  }
`